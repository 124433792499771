<template>
  <SpFinder
    id="46"
    ref="finder"
    :url="{
      data: logList
    }"
    :columns="columns"
    :search="search"
    title="操作日志"
  />
</template>

<script>
import { schemaSetting, logList } from '@/api/finder';

export default {
  data () {
    return {
      schemaSetting,
      logList,
      columns: [
        {
          "componentType": "0",
          "displayInForm": true,
          "field_type": "varchar",
          "filter": false,
          "index": 0,
          "key": "createdBy",
          "name": "操作人姓名",
          "readOnly": false,
          "search": true,
          "show": true,
        },
        {
          "componentType": "0",
          "displayInForm": true,
          "field_type": "varchar",
          "filter": false,
          "index": 1,
          "key": "module",
          "name": "操作模块",
          "readOnly": false,
          "search": false,
          "show": true,
        },
        {
          "componentType": "0",
          "displayInForm": true,
          "field_type": "longtext",
          "filter": false,
          "index": 2,
          "key": "info",
          "name": "操作内容",
          "readOnly": false,
          "search": false,
          "show": true,
        },
        {
          "componentType": "0",
          "displayInForm": true,
          "field_type": "datetime",
          "filter": false,
          "index": 3,
          "key": "createdAt",
          "name": "操作时间",
          "readOnly": false,
          "search": false,
          "show": true,
        }
      ],
      search: [
        {
          "key": "created_by",
          "name": "操作人姓名",
          "type": "text"
        }
      ],
    };
  }
};
</script>
